import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const lmsPlugin = createPlugin({
  id: 'lms',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'debug-lms' }],

});

export const LmsPage = lmsPlugin.provide(
  createRoutableExtension({
    name: 'LmsPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);
