import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 55
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"

      viewBox="0 0 2079.95 456.05"
    >
          <g>
        <path d="M406.2 45.2c-46.7 0-88.2 22.6-114.2 57.5-25.3-39.3-66.6-61.5-115.6-61.5-78.5.1-142.4 64-142.4 142.5s63.9 142.4 142.4 142.4c46.5 0 87.9-22.4 113.9-57 25.9 38.5 68.2 60.9 116 60.9 78.5 0 142.4-63.9 142.4-142.4-.1-78.5-64-142.4-142.5-142.4zm126.6 138.7-11.7 8.2-12.2-9.3c-2.3-51.2-42.3-92.7-92.8-97.5l8-10.5-9.5-13.5c64.7 4.2 116.3 57.3 118.2 122.6zM395.4 61.5l9.1 12.9-8.3 10.9c-47.3 4.5-85.4 41.4-91.9 88.1l-13.5-9.5-10.3 7.8c7.5-58.8 55.4-105.2 114.9-110.2zm-208.2-4c41 3.2 75.1 24.6 95.2 60-4.7 8.2-8.5 16.8-11.5 25.9-14.5-33.8-46.5-58.3-84.6-61.9L178 70.6l9.2-13.1zM49.8 179.9c2-65.3 53.5-118.3 118.2-122.6l-9.5 13.5 8 10.5C116 86.1 76 127.6 73.7 178.8l-12.2 9.3-11.7-8.2zm116.4 130c-59.7-4.8-107.8-51.1-115.4-110l11.1 7.8 12.7-9.7c6.5 46.4 44 83 90.9 87.9l9.1 12-8.4 12zM89.4 183.7c0-48 39-87 87-87h1.8c47.2.9 85.2 39.6 85.2 87 0 48-39.1 87-87 87-48 0-87-39-87-87zM281.6 254c0 .1-.1.2-.2.2-21.1 31.5-56 52.9-96 55.8l8.8-12.5-8.8-11.5c48.2-4.2 86.9-41.8 92.8-89.5l13-9.2 11 8.4c-1.8 21.5-9.2 41.3-20.6 58.3zm18.2.7c4.8-8.4 8.9-17.3 11.9-26.6 14.6 34 47 58.6 85.4 62.1l-8.7 11.4 8.8 12.5c-41-3-76.4-24.5-97.4-59.4zm19.4-67.1c0-47.4 38-86.1 85.2-87h1.8c48 0 87 39.1 87 87 0 48-39 87.1-87 87.1s-87-39.1-87-87.1zm97.2 126.2-8.4-12 9.1-11.9c46.9-4.9 84.6-41.5 91-88l12.7 9.7 11-7.8c-7.6 58.9-55.7 105.3-115.4 110z" fill="#ffffff" data-color="1"></path>
        <path d="M281.6 254c0 .1-.1.2-.2.2 0 0 0-.1-.1-.1l.3-.1z" fill="#ffffff" data-color="1"></path>
        <path d="M662.8 141.3v14.9h-62.3V75.8h60.8v14.9H619v17.5h37.3v14.5H619v18.6h43.8z" fill="#ffffff" data-color="1"></path>
        <path d="M751.4 75.8v80.4h-15.3L696 107.4v48.8h-18.4V75.8H693l40 48.8V75.8h18.4z" fill="#ffffff" data-color="1"></path>
        <path d="M787.1 91h-25.7V75.8h70.1V91h-25.7v65.3h-18.6l-.1-65.3z" fill="#ffffff" data-color="1"></path>
        <path d="M903.8 141.3v14.9h-62.3V75.8h60.8v14.9H860v17.5h37.3v14.5H860v18.6h43.8z" fill="#ffffff" data-color="1"></path>
        <path d="m969.8 156.3-15.5-22.4h-17.1v22.4h-18.6V75.8h34.8c21.5 0 34.9 11.1 34.9 29.2 0 12.1-6.1 20.9-16.5 25.4l18 25.9h-20zM952.4 91h-15.2v28h15.2c11.4 0 17.1-5.3 17.1-14 0-8.8-5.8-14-17.1-14z" fill="#ffffff" data-color="1"></path>
        <path d="M1072.8 105c0 17.9-13.4 29.1-34.9 29.1h-16.2v22.2H1003V75.8h34.8c21.5 0 35 11.2 35 29.2zm-18.9 0c0-8.8-5.7-14-17.1-14h-15.2v27.9h15.2c11.4 0 17.1-5.1 17.1-13.9z" fill="#ffffff" data-color="1"></path>
        <path d="m1137.2 156.3-15.5-22.4h-17.1v22.4H1086V75.8h34.8c21.5 0 34.9 11.1 34.9 29.2 0 12.1-6.1 20.9-16.5 25.4l18 25.9h-20zM1119.8 91h-15.2v28h15.2c11.4 0 17.1-5.3 17.1-14 0-8.8-5.8-14-17.1-14z" fill="#ffffff" data-color="1"></path>
        <path d="M1170.4 75.8h18.6v80.4h-18.6V75.8z" fill="#ffffff" data-color="1"></path>
        <path d="m1201.8 148.3 6.3-14c6.8 4.9 16.9 8.4 26.5 8.4 11 0 15.5-3.7 15.5-8.6 0-15.1-46.8-4.7-46.8-34.6 0-13.7 11-25 33.9-25 10.1 0 20.5 2.4 27.9 7.1l-5.7 14.1c-7.5-4.3-15.2-6.3-22.3-6.3-11 0-15.3 4.1-15.3 9.2 0 14.8 46.8 4.6 46.8 34.1 0 13.4-11.1 24.9-34.1 24.9-12.7 0-25.6-3.7-32.7-9.3z" fill="#ffffff" data-color="1"></path>
        <path d="M1343.7 141.3v14.9h-62.3V75.8h60.8v14.9h-42.3v17.5h37.3v14.5h-37.3v18.6h43.8z" fill="#ffffff" data-color="1"></path>
        <path d="M653.5 276.9h-37.3l-7.1 17.2H590l35.9-80.4h18.4l36 80.4h-19.5l-7.3-17.2zm-5.9-14.1L634.9 232l-12.8 30.8h25.5z" fill="#ffffff" data-color="1"></path>
        <path d="M686.1 258.8v-45h18.6v44.4c0 15.3 6.7 21.6 17.9 21.6 11.4 0 17.9-6.3 17.9-21.6v-44.4H759v45c0 23.7-13.6 36.8-36.4 36.8-22.9-.1-36.5-13.2-36.5-36.8z" fill="#ffffff" data-color="1"></path>
        <path d="M792.6 228.9h-25.7v-15.2H837v15.2h-25.7v65.3h-18.6v-65.3h-.1z" fill="#ffffff" data-color="1"></path>
        <path d="M840.8 253.9c0-23.9 18.6-41.6 44-41.6 25.3 0 44 17.6 44 41.6s-18.7 41.6-44 41.6c-25.4 0-44-17.7-44-41.6zm69.2 0c0-15.3-10.8-25.7-25.2-25.7s-25.2 10.5-25.2 25.7c0 15.3 10.8 25.7 25.2 25.7s25.2-10.4 25.2-25.7z" fill="#ffffff" data-color="1"></path>
        <path d="m1016 294.2-.1-48.3-23.7 39.8h-8.4L960.2 247v47.2h-17.5v-80.4h15.4l30.1 50 29.6-50h15.3l.2 80.4H1016z" fill="#ffffff" data-color="1"></path>
        <path d="M1105.5 276.9h-37.3l-7.1 17.2H1042l35.9-80.4h18.4l36 80.4h-19.5l-7.3-17.2zm-5.8-14.1-12.8-30.8-12.8 30.8h25.6z" fill="#ffffff" data-color="1"></path>
        <path d="M1153.2 228.9h-25.7v-15.2h70.1v15.2h-25.7v65.3h-18.6v-65.3h-.1z" fill="#ffffff" data-color="1"></path>
        <path d="M1207.6 213.7h18.6v80.4h-18.6v-80.4z" fill="#ffffff" data-color="1"></path>
        <path d="M1240.2 253.9c0-23.9 18.6-41.6 44-41.6 25.3 0 44 17.6 44 41.6s-18.7 41.6-44 41.6c-25.4 0-44-17.7-44-41.6zm69.2 0c0-15.3-10.8-25.7-25.2-25.7-14.4 0-25.2 10.5-25.2 25.7 0 15.3 10.8 25.7 25.2 25.7 14.4.1 25.2-10.4 25.2-25.7z" fill="#ffffff" data-color="1"></path>
        <path d="M1416 213.7v80.4h-15.3l-40.1-48.8v48.8h-18.4v-80.4h15.4l40 48.8v-48.8h18.4z" fill="#ffffff" data-color="1"></path>
    </g>
    </svg>
  );
};

export default LogoFull;
